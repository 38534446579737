<template>
    <div>
        <div class="d-flex justify-content-center">
            <div v-for="n in 5" :key="n">
                <v-icon 
                    color="primary" 
                    small 
                    :class="{no_events: editing === false}"
                    @click="setRating(n)"
                >
                    {{ getIcon(n) }}
                </v-icon>
            </div>
        </div>
        <div v-if="count !== 0">
            <span class="text-white"> {{ count }} reviews </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewStars",

    props: {
        rating: {
            type: Number,
            required: false,
            default: 3,
        },

        count: {
            type: Number,
            required: false,
            default: 0,
        },
        editing: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    methods: {
        getIcon(index) {
            if (index <= this.rating) return "fas fa-star";
            return "far fa-star";
        },

        setRating(index) {
            if(!this.editing) return
            if (index === this.rating) {
                this.$emit("update:rating", --index);
                return;
            }
            this.$emit("update:rating", index);
        },
    },
};
</script>

<style scoped>
.no_events{
    cursor: default !important;
}
</style>